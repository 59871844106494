import { render, staticRenderFns } from "./TabulacaoPage.vue?vue&type=template&id=e32f8912&scoped=true"
import script from "./TabulacaoPage.vue?vue&type=script&lang=js"
export * from "./TabulacaoPage.vue?vue&type=script&lang=js"
import style0 from "./TabulacaoPage.vue?vue&type=style&index=0&id=e32f8912&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e32f8912",
  null
  
)

export default component.exports