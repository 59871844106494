import { render, staticRenderFns } from "./ModalTabulacaoKanban.vue?vue&type=template&id=e13ffdf8&scoped=true"
import script from "./ModalTabulacaoKanban.vue?vue&type=script&lang=js"
export * from "./ModalTabulacaoKanban.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e13ffdf8",
  null
  
)

export default component.exports