<template>
    <div>
        <Breadcrumb titulo="TABULAÇÃO" :items="itensBreadcrumb" backgroundColor="#39d325" />

        <div class="mt-3 tabulacao-page container-fluid h-100 overflow-hidden">

            <div class="particao-abas-tabulacoes d-flex col-12 bg-white p-0">
                <!-- será uma aba para tipo de canais -->
                <!-- <botao-aba-tabulacao v-for="(tipo, index) in enumTiposTabulacoes"
                             :key="index"
                             :selecionado="abaAbertaTipoId === tipo.Id"
                             @click="verTabulacoesPorTipo(tipo.Id)">
            {{tipo.Descricao}}
        </botao-aba-tabulacao> -->

            </div>

            <div class="container-tabulacao p-3">

                <div class="particao-acoes-tabela-tabulacao w-100 pb-2">
                    <div class="d-flex col-12 p-0">

                        <div class="mr-auto d-flex">
                            <input-pesquisa @filtrar="pesquisarTabulacao" class="tabela-tabulacoes-input-pesquisar mr-3" />
                        </div>

                        <botao-padrao @click="abrirFormTabulacao" texto="Nova tabulação" class="tabela-tabulacoes-botao-filtrar" />

                    </div>
                </div>

                <div class="w-100">

                    <div class="particao-tabela-tabulacao bg-white mb-2">

                        <tabela-tabulacao :tabulacoes="textoPesquisa ? tabulacoesFiltradas : tabulacoesFormatadas"
                                          :isLoading="isLoading"
                                          @editar-tabulacao="abrirFormTabulacao"
                                          @remover-tabulacao="removerTabulacao"
                                          @ativarFunil="atualizarFunil">

                            <template v-slot="{ tabulacaoId }">

                                <tabela-itens-tabulacao :tabulacaoId="tabulacaoId"
                                                        :itensTabulacao="itensTabulacao"
                                                        @adicionar-item="abrirFormItemTabulacao"
                                                        @editar-item="abrirFormItemTabulacao"
                                                        @item-check="buscarItensTabulacoes"
                                                        @remover-item="removerItemTabulacao" />

                            </template>

                        </tabela-tabulacao>

                    </div>

                </div>

            </div>

            <form-tabulacao ref="formTabulacao"
                            :optionsTabulacoesPai="tabulacoesPai"
                            :itensTabulacao="itensTabulacao"
                            :optionsTabulacaoTipos="tabulacaoTipos"
                            :optionsTabulacaoTiposClientes="tabulacaoTiposClientes" />

            <form-item-tabulacao ref="formItemTabulacao" />

            <modal-tabulacao-kanban ref="modalTabulacaoKanban"
                                    @setPerfisPermitidos="ativarFunilComPermissoes"/>

        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import axios from 'axios'
    import * as SDUtils from '@/assets/js/SDUtils'
    import ExportacaoServices from '@/assets/js/services/ExportacaoServices'

    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
    import TabelaTabulacao from '@/components/tabulacao/TabelaTabulacao.vue'
    import TabelaItensTabulacao from '@/components/tabulacao/TabelaItensTabulacao.vue'
    import ModalTabulacaoKanban from '@/components/tabulacao/ModalTabulacaoKanban.vue'
    import FormTabulacao from '@/components/tabulacao/FormTabulacao.vue'
    import FormItemTabulacao from '@/components/tabulacao/FormItemTabulacao.vue'

    import BotaoPadrao from '@/components/botoes/BotaoPadrao.vue'
    import BotaoPadraoCinza from '@/components/botoes/BotaoPadraoCinza.vue'
    import InputPesquisa from '@/components/inputs/InputPesquisa.vue'

    export default {
        name: 'TabulacaoPage',
        
        components: {
            Breadcrumb,
            TabelaTabulacao,
            TabelaItensTabulacao,
            ModalTabulacaoKanban,
            FormTabulacao,
            FormItemTabulacao,
            BotaoPadrao,
            BotaoPadraoCinza,
            InputPesquisa,
        },

        data: function () {
            return {
                itensBreadcrumb: [
                    {
                        id: '1',
                        classe: 'fas fa-search',
                        texto: 'Cadastros Básicos',
                        link: '/cadastros-basicos'
                    },
                    {
                        id: '2',
                        classe: 'fas fa-search',
                        texto: 'Tabulação',
                        link: ''
                    },
                ],
                textoPesquisa: '',
                abaAbertaTipoId: 0,
                perfisPermitidos: '',
            }
        },

        watch: {
            tabulacoesPai() {

            }
        },
        
        computed: {
            ...mapGetters({
                listaTabulacoes: 'tabulacao/listaTabulacoes',
                listaTabulacoesPai: 'tabulacao/listaTabulacoesPai',
                enumTiposTabulacoes: 'tabulacao/listaTipos',
                enumTiposClientesTabulacoes: 'tabulacao/listaTiposClientes',
                listaItensTabulacoes: 'tabulacaoItens/listaItensTabulacoes',
                getQuantidadeTotalRows: 'tabulacao/getQuantidadeTotalRows',
                getPagina: 'tabulacao/getPagina',
                isLoading: 'tabulacao/isLoading'
            }),
            tabulacoesFormatadas: function () {
                if (this.listaTabulacoes) {
                    let tabulacoes = this.listaTabulacoes
                        .map(obj => ({
                            id: obj.Id,
                            ordem: obj.Ordem,
                            funil: obj.Funil,
                            perfisPermitidosFunil: obj.PerfisPermitidosFunil,
                            orcamento: obj.OrcamentoSituacao,
                            grafico: obj.Grafico,
                            titulo: obj?.Titulo,
                            obrigatorio: obj.Obrigatorio,
                            obrigatorioFinalizar: obj.ObrigatorioFinalizacao,
                            obrigatorioTransferencia: obj.ObrigatorioTrnansferencia,
                            tipo:this.enumTiposTabulacoes ? this.enumTiposTabulacoes.find(x => x.Id === obj.Tipo)?.Descricao || '' : '',
                            tipoCliente: obj.TipoCliente ? this.enumTiposClientesTabulacoes.find(x => x.Id === obj.TipoCliente)?.Descricao : 'Nenhum',
                            dependencia: obj.TabulacaoPai === null || obj.TabulacaoPai === undefined ? ""
                                : obj.TabulacaoItemPaiId !== null ? `${obj.TabulacaoPai?.Titulo} = ${(!obj.TabulacaoItemPai?.Titulo ? "Não selecionado" : obj.TabulacaoItemPai?.Titulo)}`
                                    : (obj.ValorTabulacaoPai !== null ? `${obj.TabulacaoPai?.Titulo} = ${obj.ValorTabulacaoPai ? 'Sim' : 'Não'} ` : ""),
                            kanbanPerfil: obj.kanbanPerfil
                        }
                        )).sort((obj1, obj2) => obj1.ordem - obj2.ordem );

                    return tabulacoes;
                }
                return [];
            },
            tabulacoesFiltradas() {
                if (this.textoPesquisa) {
                    return SDUtils.filtrarArrayPorObjValue(this.tabulacoesFormatadas, this.textoPesquisa)
                }
                return [];
            },
            itensTabulacao: function () {
                if (this.listaItensTabulacoes) {
                    return this.listaItensTabulacoes.map(obj => ({
                        id: obj.Id,
                        tabulacaoId: obj.TabulacaoId,
                        titulo: obj?.Titulo,
                        ordem: obj.Ordem,
                        kanban: obj.KanbanItem
                    }));
                }
                return [];
            },
            tabulacoesPai() {
                if (this.listaTabulacoesPai) {
                    return this.listaTabulacoesPai.map(prop => ({
                        text: prop?.Titulo,
                        value: prop.Id
                    }))
                }
                return [];
            },
            tabulacaoTipos () {
                if (this.enumTiposTabulacoes) {
                   //retirando Checkbox das opções pois está bugada 
                    return this.enumTiposTabulacoes.filter( prop => prop.Nome != "CheckBox")
                                                   .map(prop => ({
                                                        text: prop.Descricao,
                                                        value: prop.Id                            
                                                    }))
                }
                return [];
            },
            tabulacaoTiposClientes() {
                if (this.enumTiposClientesTabulacoes) {
                    return this.enumTiposClientesTabulacoes.map(prop => ({
                        text: prop.Descricao,
                        value: prop.Id
                    }))
                }
                return [];
            }
        },

        created() {
            this.buscarListasEnumTabulacao();
            this.buscarTabulacoes();
            this.buscarItensTabulacoes();
            this.$store.dispatch('usuarios/buscaUsuariosPerfis')
        },

        methods: {
            ...mapActions({
                buscarTabulacoes: 'tabulacao/buscaTabulacoes',
                buscarListasEnumTabulacao: 'tabulacao/buscaListasEnumTabulacoes',
                buscarItensTabulacoes: 'tabulacaoItens/buscaItensTabulacoes'
            }),
            verTabulacoesPorTipo(id) {
                this.abaAbertaTipoId = id;
            },
            abrirFormTabulacao(tabulacaoId) {
                this.$refs.formTabulacao.limpar();
                if (tabulacaoId) {
                    let tabulacaoSelecionado = this.listaTabulacoes.find(x => x.Id === tabulacaoId);
                    this.$refs.formTabulacao.editar(tabulacaoSelecionado);
                }
                this.$refs.formTabulacao.show().then(() => {
                    this.buscarTabulacoes();
                });
            },
            removerTabulacao(tabulacaoId) {
                const mensagem = 'Deseja mesmo excluir essa tabulação?'
                this.$bvModal.msgBoxConfirm(mensagem, {
                    title: 'Exclusão',
                    okTitle: 'Confirmar',
                    cancelTitle: 'Cancelar',
                    centered: true
                }).then(confirmado => {
                    if (confirmado) {
                        this.$store.dispatch('tabulacao/remover', tabulacaoId);
                    }
                })
            },
            pesquisarTabulacao(texto) {
                this.textoPesquisa = texto.toLowerCase();
            },
            exportarTabulacoes() {
                let dadosExportTabulacoes = this.listaTabulacoes.map(prop => ({
                    'Titulo': prop?.Titulo,
                    'Obrigatório': prop.Obrigatorio,
                    'Obrigatório finalizar': prop.ObrigatorioFinalizacao,
                    'Obrigatório transferência': prop.ObrigatorioTrnansferencia,
                    'Tipo de resposta': this.enumTiposTabulacoes.find(x => x.Id === prop.Tipo)?.Descricao || '',
                    'Tipo de cliente': prop.TipoCliente ? this.enumTiposClientesTabulacoes.find(x => x.Id === prop.TipoCliente)?.Descricao
                        : 'Nenhum',
                    'Dependência': prop.TabulacaoPai === null ? ""
                        : prop.TabulacaoItemPaiId !== null ? `${prop.TabulacaoPai.Titulo} = ${(!obj.TabulacaoItemPai?.Titulo ? "Não selecionado" : obj.TabulacaoItemPai?.Titulo) }`
                            : (prop.ValorTabulacaoPai !== null ? `${prop.TabulacaoPai.Titulo} = ${prop.ValorTabulacaoPai ? 'Sim' : 'Não'} ` : "")
                }))

                ExportacaoServices.exportarPlanilhaSimples(dadosExportTabulacoes, 'tabela_tabulações.xlsx')
            },
            abrirFormItemTabulacao(itemId, tabulacaoId) {
                this.$refs.formItemTabulacao.limpar();
                if (tabulacaoId) {
                    this.$refs.formItemTabulacao.setarTabulacao(tabulacaoId);
                }

                if (itemId) {
                    let itemTabulacao = this.listaItensTabulacoes.find(x => x.Id === itemId);
                    this.$refs.formItemTabulacao.editar(itemTabulacao);
                }

                this.$refs.formItemTabulacao.show().then(() => {
                    this.buscarItensTabulacoes();
                });
            },
            removerItemTabulacao(itemId) {
                const mensagem = 'Deseja mesmo excluir esse item?'
                this.$bvModal.msgBoxConfirm(mensagem, {
                    title: 'Exclusão',
                    okTitle: 'Confirmar',
                    cancelTitle: 'Cancelar',
                    centered: true
                }).then(confirmado => {
                    if (confirmado) {
                        this.$store.dispatch('tabulacaoItens/remover', itemId);
                        this.buscarItensTabulacoes();
                    }
                })
            },
            atualizarFunil(funilAtivado, id) {
                if (funilAtivado) {
                    const tabulacao = this.listaTabulacoes.find(tab => tab.Id === id)
                    this.$refs.modalTabulacaoKanban.show(id, tabulacao.PerfisPermitidosFunil, tabulacao.KanbanPerfil)
                    return
                }

                this.salvarAtualizacaoFunil(funilAtivado, id)
            },
            ativarFunilComPermissoes({ id, perfis, perfilKanban }) {
              
                this.salvarAtualizacaoFunil(true, id, perfis,perfilKanban )
            },
            salvarAtualizacaoFunil(ativar, tabulacaoId, perfisPermitidos = '', perfilKanban = 0) {
                $("#caixa_carregamento").show();
                axios.post(`/api/tabulacao/setarKanban/${tabulacaoId}/${ativar ? 'adicionar' : 'remover'}/${perfilKanban}?perfis=${perfisPermitidos}`)
                    .then((response) => {
                        $("#caixa_carregamento").hide()
                        this.buscarTabulacoes();
                    }, (error) => {
                        console.error(error);
                        $("#caixa_carregamento").hide()
                    });
            }
        }
    }
</script>

<style scoped>
    .tabulacao-page-titulo {
        color: var(--cinza-5);
    }

    .container-tabulacao {
        height: calc(100% - 157px);
        background-color: var(--cinza-3);
        font-size:12px;
    }

    .particao-tabela-tabulacao {
        height: calc(100vh - 250px)
    }

    .particao-acoes-tabela-tabulacao, .tabela-tabulacoes-filtros {
        align-self: center;
    }

    .tabela-tabulacoes-input-pesquisar {
        width: 340px !important;
    }

    .tabela-tabulacoes-botao-filtrar {
        min-width: 5rem;
    }

</style>