<template>
    <b-modal 
        class="modal-formulario-tabulacao"
        ref="formularioTabulacao"
        size="lg"
        hide-footer
        centered
        header-class="d-none"
        content-class="rounded-0"
    >
        <b-container fluid>

            <h5 class="form-tabulacao-titulo my-3">Nova/Editar Tabulação</h5>

            <b-row cols="2">
                <b-col cols="2">
                    <input-padrao label="Ordem" tipo="number" v-model="ordem" />
                </b-col>

                <b-col cols="10">
                    <input-padrao label="Título" v-model.trim="$v.titulo.$model" />
                    <p class="txt-erro-validacao" v-if="clickSalvar && $v.titulo.$invalid">Campo título é obrigátório</p>
                </b-col>

                <b-col class="mt-3">
                    <label for="check-finalizacao" class="d-flex">
                        <b-form-checkbox id="check-finalizacao" class="mr-1" v-model="finalizacao"></b-form-checkbox>
                        Campo obrigatório para finalização
                    </label>
                </b-col>

                <b-col class="mt-3">
                    <label for="check-transferencia" class="d-flex">
                        <b-form-checkbox id="check-transferencia" class="mr-1" v-model="transferencia"></b-form-checkbox>
                        Campo obrigatório para transferência
                    </label>
                </b-col>

                <b-col cols="7" class="mt-2">
                    <input-select label="Tipo de resposta" :opcoes="optionsTabulacaoTipos" v-model.trim="$v.tipo.$model" />
                    <p class="txt-erro-validacao" v-if="clickSalvar && $v.tipo.$invalid">Selecione um tipo</p>
                </b-col>

                <b-col cols="5" class="mt-2">
                    <input-select label="Campo Cliente" :opcoes="optionsTabulacaoTiposClientes" v-model.trim="$v.tipoCliente.$model" />
                    <p class="txt-erro-validacao" v-if="clickSalvar && $v.tipoCliente.$invalid">Selecione um campo cliente</p>
                </b-col>

            </b-row>

            <hr />

            <b-row cols="3">

                <b-col cols="5">
                    <input-select label="Tabulação Pai"
                                  :opcoes="optionsTabulacoesPai"
                                  v-model="tabulacaoPaiId"
                                  @change="limparCampoItemPai" />
                </b-col>

                <b-col cols="5" :class="!tabulacaoPaiId ? 'invisible' : 'visible'">
                    <input-select label="Tabulação Item Pai"
                                  :opcoes="optionsItensTabulacaoPai.sort()"
                                  v-model="tabulacaoItemPaiId"
                                  @change="limparCampoItemPai" />
                </b-col>

                <b-col cols="2">
                    <input-padrao label="Valor" tipo="number" v-model="valor" />
                </b-col>

            </b-row>

            <div class="w-100 my-4 text-right">
                <botao-modal-cancel class="d-inline mr-2" @click="fechar">Cancelar</botao-modal-cancel>
                <b-button id="botaoSalvar" variant="success" @click="salvar" :disabled="isSending" class="rounded-0">
                    <span v-if="isSending">
                        <b-spinner small class="mr-1"></b-spinner>
                        Salvando
                    </span>
                    <span v-else>
                        Salvar
                    </span>
                </b-button>
            </div>

        </b-container>

    </b-modal>
</template>

<script>
    import axios from 'axios'

    import { required } from "vuelidate/lib/validators";

    import BotaoModalOk from '@/components/botoes/BotaoModalOk.vue'
    import BotaoModalCancel from '@/components/botoes/BotaoModalCancel.vue'
    import InputPadrao from '@/components/inputs/InputPadrao.vue'
    import InputSelect from '@/components/inputs/InputSelect.vue'

    export default {
        name: 'FormTabulacao',
        components: {
            BotaoModalOk,
            BotaoModalCancel,
            InputPadrao,
            InputSelect
        },

        props: {
            optionsTabulacoesPai: { Array, default: () => [] },
            optionsTabulacaoTipos: { Array, default: () => []},
            optionsTabulacaoTiposClientes: { Array, default: () => [] },

            itensTabulacao: { Array, default: () => [] },
        },

        data() {
            return {
                id: '',
                empresaId: '',
                ordem: '',
                titulo: '',
                finalizacao: false,
                transferencia: false,
                tipo: '',
                tipoCliente: '',
                tabulacaoPaiId: '',
                isSending:false,
                tabulacaoItemPaiId: '',
                valor: '',
                kanbanPerfil: '',
                funil: '',
                perfisPermitidos: '',
                enviou: false,
                clickSalvar: false
            }
        },

        computed: {
            optionsItensTabulacaoPai() {
               
                if (this.itensTabulacao) {
                    return this.itensTabulacao.filter(x => x.tabulacaoId === this.tabulacaoPaiId )
                        .map(x => ({
                            text: x.titulo,
                            value: x.id
                        })).sort()
                }
                return []
            }
        },

        methods: {
            show() {
                this.$refs['formularioTabulacao'].show();

                return new Promise(resolve => this.enviou = resolve);
            },
            fechar() {
                this.limpar();
                this.$refs['formularioTabulacao'].hide();
            },
            editar(tab) {
                this.id = tab.Id;
                this.empresaId = tab.EmpresaId;
                this.ordem = tab.Ordem;
                this.titulo = tab.Titulo;
                this.tipo = tab.Tipo;
                this.funil = tab.Funil ? tab.Funil : false,
                this.finalizacao = tab.ObrigatorioFinalizacao;
                this.transferencia = tab.ObrigatorioTrnansferencia;
                this.tipoCliente = tab.TipoCliente;
                this.kanbanPerfil = tab.kanbanPerfil ? tab.kanbanPerfil : 0;
                this.perfisPermitidos = tab.PerfisPermitidosFunil ? tab.PerfisPermitidosFunil : '';
                this.tabulacaoPaiId = tab.TabulacaoPaiId ? tab.TabulacaoPaiId : '';
                this.tabulacaoItemPaiId = tab.TabulacaoItemPaiId ? tab.TabulacaoItemPaiId : '';
                this.valor = tab.ValorTabulacaoPai;
            },
            limpar() {
                this.clickSalvar = false;

                this.id = '';
                this.empresaId = '';
                this.ordem = '';
                this.titulo = '';
                this.finalizacao =false;
                this.transferencia = false;
                this.tipo = '';
                this.perfisPermitidos = '',
                this.funil = '',
                this.tipoCliente = '';
                this.tabulacaoPaiId = '';
                this.tabulacaoItemPaiId = '';
                this.valor = '';
            },
            limparCampoItemPai() {
                this.tabulacaoItemPai = ''
            },
            salvar() {
                this.isSending = true;
                this.clickSalvar = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.isSending = false;
                    return
                }

                if (this.tabulacaoPaiId == '') {
                    this.tabulacaoItemPaiId = ''
                }

                let dados = {
                    "Id": this.id,
                    "EmpresaId": this.empresaId,
                    "Ordem": this.ordem,
                    "Titulo": this.titulo,
                    "Tipo": this.tipo,
                    "ObrigatorioFinalizacao": this.finalizacao,
                    "ObrigatorioTrnansferencia": this.transferencia,
                    "TipoCliente": this.tipoCliente,
                    "KanbanPerfils": this.kanbanPerfil,
                    "Funil" : this.funil,
                    "PerfisPermitidosFunil" : this.perfisPermitidos,
                    "TabulacaoPaiId": this.tabulacaoPaiId,
                    "TabulacaoItemPaiId": this.tabulacaoItemPaiId,
                    "ValorTabulacaoPai": this.valor
                }
                if(this.id){
                    axios.put(`api/Tabulacao`, dados).then(response => {
                        this.enviou(true);
                        this.isSending = false;
                        this.$bvToast.toast(`Tabulação editada com sucesso.`, {
                            title: "Sucesso",
                            toaster: "b-toaster-top-right",
                            variant: "success",
                            solid: true,
                            autoHideDelay: 5000
                        });
                        this.fechar();
                    }).catch(error => {
                        console.error(error);
                        this.isSending = false;
                        this.fechar();
                        this.$bvToast.toast(`Ocorreu um erro ao editar tabulação.`, {
                            title: "Ops, algo deu errado...",
                            toaster: "b-toaster-top-right",
                            variant: "danger",
                            solid: true,
                            autoHideDelay: 5000
                        });
                    })
                }
                else{
                    delete dados.Id;
                    delete dados.EmpresaId;
                    axios.post(`api/Tabulacao`, dados).then(response => {
                        this.enviou(true);
                        this.isSending = false;
                        this.$bvToast.toast(`Tabulação criada com sucesso.`, {
                            title: "Sucesso",
                            toaster: "b-toaster-top-right",
                            variant: "success",
                            solid: true,
                            autoHideDelay: 5000
                        });
                        this.fechar();
                    }).catch(error => {
                        console.error(error);
                        this.isSending = false;
                        this.fechar();
                        this.$bvToast.toast(`Ocorreu um erro ao criar tabulação.`, {
                            title: "Ops, algo deu errado...",
                            toaster: "b-toaster-top-right",
                            variant: "danger",
                            solid: true,
                            autoHideDelay: 5000
                        });
                    })
                }
            },
        },
        
        validations: {
            titulo: { required },
            tipo: { required },
            tipoCliente: { required },
        },
    }
</script>

<style scoped>
    .form-tabulacao-titulo {
        color: var(--cor-primaria-cliente);
    }
    
    label {
        font-size: 14px;
        margin: 5px 0 2px 0;
    }

    .txt-erro-validacao {
        color: red;
        margin: 0;
        font-size: 12px;
    }
</style>