<template>

    <b-card class="cards-itens-tabulacao">

        <div class="particao-botoes-card w-100 text-right p-2">
            <BotaoPadrao texto="Novo item" @click="$emit('adicionar-item', null, tabulacaoId)" />
        </div>

        <b-table :fields="colsItensTabulacao"
                 :items="itensFiltradosPorTabulacao"
                 :sort-by.sync="colsItensTabulacao[0].key"
                 sort-icon-left
                 bordered
                 small
                 class="tabela-itens-tabulacao m-auto">
            <template #cell(kanbanItens)="data">
                <div class="w-100 text-center col_check">
                    <b-form-checkbox v-on:change="selecionarKanbanItem(data.item.id)"
                                     :id="data.item.id"
                                     v-model="data.item.kanban"
                                     @click="$emit('item-check')"
                                     >

                    </b-form-checkbox>
                </div>
            </template>
            <template #cell(botoes)="row">
                <div class="text-center">
                    <botao-tab-edit @click="$emit('editar-item', row.item.id, null)" />
                    <botao-tab-remove @click="$emit('remover-item', row.item.id)" />
                </div>
            </template>
        </b-table>
    </b-card>

</template>

<script>
    import BotaoPadrao from '@/components/botoes/BotaoPadrao.vue'
    import BotaoTabEdit from '@/components/botoes/BotaoTabEdit.vue'
    import BotaoTabRemove from '@/components/botoes/BotaoTabRemove.vue'
    import axios from 'axios'
    export default {
        name: 'TabelaItensTabulacao',
        components: {
            BotaoPadrao,
            BotaoTabEdit,
            BotaoTabRemove
        },

        props: {
            tabulacaoId: String,
            itensTabulacao: {
                type: Array,
                default: () => []
            },
        },

        data() {
            return {
                colsItensTabulacao: [
                    { key: 'ordem', label: 'Ordem', thStyle: 'width: 70px', sortable: true },
                    { key: 'titulo', label: 'Titulo', sortable: true },
                    { key: 'kanbanItens', label: 'Kanban', thStyle: 'width: 100px' },
                    { key: 'botoes', label: '', thStyle: 'width: 100px' }
                ],
            }
        },

        computed: {
            itensFiltradosPorTabulacao() {
                return this.itensTabulacao?.filter(x => x.tabulacaoId === this.tabulacaoId)
            }
        },
        methods: {
            selecionarKanbanItem: function (id) {
                const acao = $("#" + id).is(":checked") == false ? "remover" : "adicionar";
                $("#caixa_carregamento").show();
                axios.post('/api/tabulacaoItem/setarKanbanItem/' + id + "/" + acao)
                    .then((response) => {
                        $("#caixa_carregamento").hide()
                    }, (error) => {
                        console.log(error);
                        $("#caixa_carregamento").hide()
                    });
                
            }
        }
    }

</script>

<style scoped>
    .cards-itens-tabulacao {
        height: auto;
    }
</style>
