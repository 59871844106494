<template>
    <b-table :items="tabulacoes"
             :fields="colsTabulacoes"
             :busy="isLoading"
             sticky-header
             responsive="sm"
             striped
             class="tabela-tabulacao-btable app-scroll-custom mh-100">

        <template #table-busy>
            <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"/>
                <strong> Loading...</strong>
            </div>
        </template>

        <template #cell(collapse)="tab">
            <!-- toggleDetails função bootstrap para abrir collapse de itens da tabulacao -->
            <botao-icone-dinamico :classeIcone="tab.detailsShowing ? 'fas fa-angle-down' : 'fas fa-angle-right'"
                                  @click="tab.toggleDetails" />
        </template>

        <template #cell(obrigatorio)="data">
            <div class="w-100 text-center col_check">
                <b-form-checkbox v-model="data.item.obrigatorio" disabled />
            </div>
        </template>

        <template #cell(obrigatorioFinalizar)="data">
            <div class="w-100 text-center col_check">
                <b-form-checkbox v-model="data.item.obrigatorioFinalizar" disabled />
            </div>
        </template>

        <template #cell(obrigatorioTransferencia)="data">
            <div class="w-100 text-center col_check">
                <b-form-checkbox v-model="data.item.obrigatorioTransferencia" disabled />
            </div>
        </template>
        <template #cell(funil)="{ item }">
            <div class="w-100 text-center col_check">
                <b-form-checkbox v-if="item.tipo === 'Lista Suspensa'"
                                 v-model="item.funil"
                                 @change="$emit('ativarFunil', $event, item.id)">
                </b-form-checkbox>
                <botao-icone-dinamico v-if="item.funil"
                                      classeIcone="fas fa-user-shield"
                                      size=".7rem"
                                      @click="$emit('ativarFunil', true, item.id)" />
            </div>
        </template>
        <template #cell(orcamento)="data">
            <div class="w-100 text-center col_check">
                <b-form-radio v-if="data.item.tipo == 'Lista Suspensa'"
                              name="orcamento"
                              v-model="selected"
                              :value="verificar(data.item.id, data.item.orcamento)"
                              v-on:change="selecionarOrcamento(data.item.id)">
                </b-form-radio>
            </div>
        </template>
        <!-- collapse de itens da tabulação -->
        <template #row-details="tabulacao">

            <slot :tabulacaoId="tabulacao.item.id">
            </slot>

        </template>

        <template #cell(botoes)="row">
            <div class="w-100">

                <botao-tab-edit @click="editarTabulacao(row.item.id)" />
                <botao-tab-remove @click="removerTabulacao(row.item.id)" />

            </div>
        </template>

    </b-table>
</template>

<script>
    import BotaoTabEdit from '@/components/botoes/BotaoTabEdit.vue'
    import BotaoTabRemove from '@/components/botoes/BotaoTabRemove.vue'
    import BotaoIconeDinamico from '@/components/botoes/BotaoIconeDinamico.vue'
    import axios from 'axios'

    export default {
        name: 'TabelaTabulacao',
        components: {
            BotaoTabEdit,
            BotaoTabRemove,
            BotaoIconeDinamico
        },

        props: {
            tabulacoes: {
                type: Array,
                default: () => []
            },
            isLoading: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                selected: 'first',
                selected_kanban: null,
                selected_orcamento: true,
                colsTabulacoes: [
                    { key: 'collapse', label: '', class: 'position-relative' },
                    { key: 'ordem', label: 'Ordem', sortable: true, sortDirection: 'asc' },
                    { key: 'titulo', label: 'Titulo', sortable: true, sortDirection: 'asc' },
                    { key: 'tipoCliente', label: 'Campo', sortable: true, sortDirection: 'asc' },
                    { key: 'obrigatorio', label: 'Obrigatório', sortable: true, sortDirection: 'asc' },
                    { key: 'tipo', label: 'Tipo', sortable: true, sortDirection: 'asc' },
                    { key: 'obrigatorioFinalizar', label: 'Obg finalização', sortable: true, sortDirection: 'asc' },
                    { key: 'obrigatorioTransferencia', label: 'Obg transferência', sortable: true, sortDirection: 'asc' },
                    { key: 'dependencia', label: 'Dependência', sortable: true, sortDirection: 'asc' },
                    { key: 'funil', label: 'Kanban', sortable: true, sortDirection: 'asc' },
                    { key: 'orcamento', label: 'Orçamento', sortable: true, sortDirection: 'asc'},
                    { key: 'botoes', label: '', class: 'position-relative' },
                ],

                txtPesquisaItem: '',
            }
        },

        methods: {
            editarTabulacao(id) {
                this.$emit('editar-tabulacao', id);
            },
            removerTabulacao(id) {
                this.$emit('remover-tabulacao', id);
            },
            selecionarOrcamento(id) {
                $("#caixa_carregamento").show();
                axios.post('/api/tabulacao/setarOrcamento/' + id )
                    .then((response) => {
                        $("#caixa_carregamento").hide()
                    }, (error) => {
                        console.log(error);
                        $("#caixa_carregamento").hide()
                    });
            },
            verificar(id, funil) {
                if (funil) {
                    this.selected = id;
                }
                return id;

            }
        },
    }
</script>

<style scoped>
    .tabela-tabulacao-btable {
        background-color: #fff;
        overflow-y: auto;
    }

    .app-scroll-custom {
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: grey lightgrey;
    }
    .col_check {
        display:flex
    }
</style>